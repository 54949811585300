import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    highlight(e: MouseEvent): void {
        e.preventDefault();
        const link = e.currentTarget as HTMLElement;
        const target = document.getElementById(link.dataset.target!) as HTMLElement;
        if(!target) return;
        target.scrollIntoView({ behavior: "smooth", block: "nearest"});
        target.classList.remove("animate-highlight");
        window.requestAnimationFrame(() => {
            target.classList.add("animate-highlight");
        });

    }
}
